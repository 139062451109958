import Image from "next/image";
import { styled } from "stitches.config";

const LinkStyled = styled("a", {
  lineHeight: 0,
});

const ImageStyled = styled(Image, {
  lineHeight: 1,
  padding: 0,
});

export function Logo({ onClick }) {
  return (
    <LinkStyled href="https://gleevery.com/" onClick={onClick}>
      <ImageStyled src="/svg/gleevery.svg" alt="Logo Gleevery" width={108} height={26} />
    </LinkStyled>
  );
}
